import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let matchMedia = gsap.matchMedia();

document.addEventListener('DOMContentLoaded', () => {
	const section = document.querySelectorAll('.case-slider');

	section.forEach((slider) => {
		const sliderContainer = slider.querySelector('.cases-slider-container');
		const articles = slider.querySelectorAll('article');
		const countSlides = articles.length;
		const featuredCase = slider.querySelector('.featured-case');
		const featuredLink = featuredCase?.querySelector('.featured-case a');
		const video = featuredCase?.querySelector('video');
		const uspsWrapper = slider.querySelector('.usps-wrapper');
		const usps = uspsWrapper?.querySelectorAll('.usps li');

		featuredLink?.addEventListener('mouseenter', () => {
			video.play();
		});

		featuredLink?.parentNode.addEventListener('mouseleave', () => {
			video.pause();
			video.currentTime = 0;
		});

		matchMedia.add('(min-width:800px)', () => {
			gsap.to(sliderContainer, {
				left: 'unset',
				right: '50%',
				scrollTrigger: {
					trigger: slider,
					start: 'top 20px',
					end: '+=' + (countSlides * 100),
					pin: true,
					scrub: true,
					anticipatePin: 1,
				}
			});

			gsap.from(usps, { y: -20, opacity: 0, stagger: 0.2, scrollTrigger: { trigger: slider, start: 'bottom bottom', } });
		})
	});
});
